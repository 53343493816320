import styled from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

export const Well = styled.div`
  padding: 1.5rem 2rem;
  min-height: 20rem;
  background: #eee;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SearchContainer = styled.div`
  position: relative;
`
export const SearchResults = styled.div`
  display: none;
  position: absolute;
  z-index: ${zIndex.base + 1};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  left: 0; right: 0;
  background: white;

  ${props => props.visible && `display: block;`}
`
